<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>

        <vs-table ref="table" pagination :max-items="this.$store.state.pps" search :data="events">
            
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

              <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">

                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </button>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Delete</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Archive</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Another Action</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>

                <!-- ADD NEW -->
                <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="$router.push('/event/new').catch(() => {})">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Add New</span>
                  </button>
                </div>
                <!-- REFRESH -->
                <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
                  <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Refresh</span>
                </button>
              </div>
            </div>

            <template slot="thead">
                <vs-th class="add_th" sort-key="event_id">S.NO</vs-th>
                <vs-th class="add_th" sort-key="title">Title</vs-th>
                <vs-th class="add_th" sort-key="address">Address</vs-th>
                <vs-th class="add_th" sort-key="event_time">Time</vs-th>
                <vs-th class="add_th" sort-key="created_at">Date</vs-th>
                <vs-th  class="add_th" sort-key="status">Status</vs-th>
                <vs-th class="add_th">Action</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                     <vs-td :data="data[indextr].title">
                        {{indextr+1}}
                    </vs-td>
                    <vs-td :data="data[indextr].title">
                        {{data[indextr].title}}
                    </vs-td>

                    <vs-td :data="data[indextr].address">
                        {{data[indextr].address}}
                    </vs-td>

                    <vs-td :data="data[indextr].event_time">
                        {{event_time(data[indextr].event_time)}}
                    </vs-td>

                    <vs-td :data="data[indextr].created_at">
                        {{date_format(data[indextr].created_at)}}
                    </vs-td>

                    <vs-td :data="data[indextr].status">
                        <vs-chip v-if="data[indextr].status==0" :color="'#d9534f'" class="product-order-status">InActive</vs-chip>
                        <vs-chip v-if="data[indextr].status==1" :color="'#5cb85c'" class="product-order-status">Active</vs-chip>
                        <vs-chip v-if="data[indextr].status==2" :color="'#d9534f'" class="product-order-status">Expired</vs-chip>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap" v-if="org_id == data[indextr].org_id">
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="$router.push('/event/edit/'+tr.event_id).catch(() => {})" />
                      <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click="confirmDeleteRecord(tr.event_id,data[indextr].title)" />
                    </vs-td>
                    <vs-td class="whitespace-no-wrap" v-if="org_id !== data[indextr].org_id">
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="disableDeleteRecord()" />
                      <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click="disableDeleteRecord()" />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

    </vx-card>
</template>

<script>
// import moduleEvent          from '@/store/event/moduleEvent.js'

export default {
  data () {
    return {
      formData             : {},
      displayPrompt        : false,
      'org_id':this.$store.state.AppActiveUser.org_id
    }
  },
  components: {

  },
  computed: {
    events ()     { 
      return this.$store.getters['event/queriedEvents']      
    },
    validateForm () {
      return !this.errors.any() && this.formData.event_name !== '' && this.formData.event_code !== ''
    }
  },
  methods: {
    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },
    event_time (datetime) {
      const d = new Date(datetime)
      return d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear()  + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
    },
    refreshData () {
      this.$vs.loading()
      this.$store.dispatch('event/fetchEvents').then(() => { this.$vs.loading.close() })
    },
      confirmDeleteRecord (id,name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You Are About To Delete Event Name Of "${name}"`,
        accept: this.deleteEvent,
        acceptText: 'Confirm',
        parameters: id
      })
    },
    deleteEvent (parameters) {
       this.$vs.loading()
      this.$store.dispatch('event/deleteEvent', parameters).then(() => { 
                  this.$store.dispatch('event/fetchEvents')
                  this.$vs.loading.close()
                  const error = "Event Removed Successfully...!!!"
                  this.notif(error)     
       }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
    },
     disableDeleteRecord(){
       this.$vs.notify({
        title: 'Alert',
        text: "Permission Deined..!!",
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })    
    },
      notif (error) {
      this.$vs.notify({
        title: 'Removed',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
  },
  created () {
    // this.$store.registerModule('event', moduleEvent)
    if(this.events.length == 0){
      this.$vs.loading()
      this.$store.dispatch('event/fetchEvents').then(() => { this.$vs.loading.close() })
    }
    
  },
  beforeDestroy() {
    // this.$store.unregisterModule('event')
  }
}
</script>
